import { HttpInterceptorFn, HttpXsrfTokenExtractor } from '@angular/common/http';
import { inject } from '@angular/core';

export const csrfInterceptor: HttpInterceptorFn = (request, next) => {
  const headerName = 'X-XSRF-TOKEN';
  const tokenService = inject(HttpXsrfTokenExtractor);
  // todo: create customization to not including this token interceptor
  let req = request.clone({ withCredentials: true });
  if (req.method === 'GET' || req.method === 'HEAD') {
    return next(req);
  }
  console.log(tokenService.getToken());
  const token = tokenService.getToken();

  // Be careful not to overwrite an existing header of the same name.
  if (token !== null && !req.headers.has(headerName)) {
    req = req.clone({ headers: req.headers.set(headerName, token) });
  }
  return next(req);
};
