import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import type { ToastOptions } from '@ionic/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private defaultOptions: ToastOptions = {
    message: '',
    duration: 2000,
    color: 'primary',
    position: 'bottom',
  };

  constructor(private toastController: ToastController) {}

  public async presentToast(options: ToastOptions): Promise<void> {
    const toastOptions = { ...this.defaultOptions, ...options };
    const toast = await this.toastController.create(toastOptions);
    toast.present();
  }

  public async presentSuccessToast(message: string): Promise<void> {
    await this.presentToast({ message, color: 'success' });
  }

  public async presentDangerToast(message: string): Promise<void> {
    await this.presentToast({ message, color: 'danger' });
  }
}
