import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { catchError, from, mergeMap, Observable, tap, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoaderService {
  constructor(private loadingCtrl: LoadingController) {}

  /**
   * Display loading on whole screen
   */
  public async showLoader(): Promise<HTMLIonLoadingElement> {
    const loading = await this.loadingCtrl.create({
      message: 'Loading...',
      spinner: 'circles',
    });
    return await loading.present().then(() => loading);
  }

  /**
   * Display screen blocking loading during requests
   * @param observable
   */
  public showLoaderObs<T>(observable: Observable<T>): Observable<T> {
    return from(this.showLoader()).pipe(
      mergeMap(loader =>
        observable.pipe(
          tap(() => {
            loader.dismiss();
          }),
          catchError(err => {
            console.log(err);
            loader.dismiss();
            return throwError(err);
          }),
        ),
      ),
    );
  }
}
