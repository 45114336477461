import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

import { STORAGE_KEYS } from '../models/storage-keys';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private storage: Storage = new Storage();

  constructor() {
    this.init();
  }

  private async init(): Promise<Storage> {
    return (this.storage = await this.storage.create());
  }

  public getStorage(): Storage {
    return this.storage;
  }

  public setData<T>(key: STORAGE_KEYS, data: T): Promise<T> {
    return this.storage.set(key, data);
  }

  public getData<T>(key: STORAGE_KEYS): Promise<T> {
    return this.storage.get(key);
  }

  public clearStorage(): Promise<void> {
    return this.storage.clear();
  }

  public clearKeys(...keysToRemove: STORAGE_KEYS[]): Promise<null[]> {
    return Promise.all(keysToRemove.map(key => this.storage.remove(key)));
  }
}
