import { map, Observable } from 'rxjs';

type WithData<T> = {
  data: T;
};

export const mapToData =
  <T>() =>
  (source: Observable<WithData<T>>): Observable<T> =>
    source.pipe(map(response => response.data));
