import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { mergeMap, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CsrfService {
  constructor(private http: HttpClient) {}

  public initCsrf<T>(obs: Observable<T>): Observable<T> {
    return this.http
      .get(`${environment.backendUrl}sanctum/csrf-cookie`, {
        withCredentials: true,
      })
      .pipe(mergeMap(() => obs));
  }
}
