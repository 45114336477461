import { HttpXsrfTokenExtractor } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { map } from 'rxjs';

import { AuthService } from '../services/auth.service';

export const authGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const tokenService = inject(HttpXsrfTokenExtractor);
  const token = tokenService.getToken();
  if (token) {
    return authService.getUser().pipe(map(() => true));
  } else {
    return router.navigate(['/', 'auth']).then(() => false);
  }
};
