import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { ApiResponseObject } from '@models/api/api-response';
import { STORAGE_KEYS } from '@models/storage-keys';
import { User } from '@models/user';
import { BehaviorSubject, catchError, mergeMap, Observable, of, switchMap, tap } from 'rxjs';

import { environment } from '../../environments/environment';
import { mapToData } from '../helpers/mapToData.helper';

import { CsrfService } from './csrf.service';
import { LoaderService } from './loader.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  constructor(
    private http: HttpClient,
    private router: Router,
    private navCtrl: NavController,
    private storageService: StorageService,
    private csrfService: CsrfService,
    private loaderService: LoaderService,
  ) {
    this.getUserFromStorage().then();
  }

  /**
   * Registers a user with the given credentials.
   *
   * @param {Object} credentials - The user credentials.
   * @param {string | null} credentials.email - The user's email.
   * @param {string | null} credentials.password - The user's password.
   * @param {string | null} credentials.password_confirmation - Confirmation of the user's password.
   *
   * @return {Observable<User>} - An Observable that emits the user object upon successful registration.
   */
  public registration(credentials: {
    email: string | null;
    password: string | null;
    password_confirmation: string | null;
  }): Observable<User> {
    return this.loaderService
      .showLoaderObs(
        this.csrfService.initCsrf(
          this.http.post<ApiResponseObject<User>>(
            `${environment.backendApiUrl}v1/auth/register`,
            credentials,
            {
              withCredentials: true,
            },
          ),
        ),
      )
      .pipe(
        mapToData(),
        tap(() => {
          this.navCtrl.navigateBack(['/', 'app', 'login']);
        }),
      );
  }

  /**
   *  Log in user (without setting user to storage, AuthGuard is taking care about it)
   * @param credentials
   */
  public logIn(credentials: { email: string; password: string }): Observable<User> {
    return this.loaderService
      .showLoaderObs(
        this.csrfService.initCsrf(
          this.http.post<ApiResponseObject<User>>(
            `${environment.backendApiUrl}v1/auth/login`,
            credentials,
          ),
        ),
      )
      .pipe(
        mapToData(),
        mergeMap(user =>
          this.navCtrl.navigateRoot(['/', 'app'], { animated: true }).then(() => user),
        ),
      );
  }

  /**
   * Logs the user out and clears the storage.
   * @returns {Observable<boolean>} Observable that emits a boolean value indicating whether the logout was successful or not.
   */
  public logOut(): Observable<boolean> {
    return this.http
      .post<ApiResponseObject<boolean>>(`${environment.backendApiUrl}v1/auth/logout`, {})
      .pipe(
        catchError(() => of(null)),
        switchMap(() => this.storageService.clearStorage()),
        switchMap(() =>
          this.navCtrl.navigateRoot(['/', 'auth'], {
            animated: true,
            animationDirection: 'back',
            replaceUrl: true,
          }),
        ),
      );
  }

  public getUser(): Observable<User> {
    return this.http.get<ApiResponseObject<User>>(`${environment.backendApiUrl}v1/auth/me`).pipe(
      mapToData(),
      mergeMap(async user => {
        await this.setUserToStorage(user);
        return user;
      }),
    );
  }

  // Update the BehaviorSubject based on user data stored in storage
  private async getUserFromStorage(): Promise<User | undefined> {
    const user = await this.storageService.getData<User>(STORAGE_KEYS.USER);
    this._user.next(user ?? null);
    return user;
  }

  // Update the BehaviorSubject based on user authorization and set it to storage
  public setUserToStorage(user: User): Promise<User> {
    this._user.next(user);
    return this.storageService.setData(STORAGE_KEYS.USER, user);
  }

  public forgottenPassword(credentials: { email: string | null }): Observable<unknown> {
    return this.http.post<ApiResponseObject<unknown>>(
      `${environment.backendApiUrl}v1/auth/forgot-password`,
      credentials,
    );
  }

  public setPassword(credentials: {
    token: string | null;
    password: string | null;
    password_confirmation: string | null;
  }): Observable<unknown> {
    return this.http.post<ApiResponseObject<unknown>>(
      `${environment.backendApiUrl}v1/auth/set-password`,
      credentials,
    );
  }
}
